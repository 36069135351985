import {
  AboutItemInterface,
  SectionComponent,
  Sections,
} from '@/features/Homepage/homepage.types';
import { About } from '@/features/Sections/About';

export const renderSectionComponent = (
  sectionName: string, components: Array<SectionComponent>
) => {
  switch (sectionName) {
    case Sections.ABOUT:
      return <About components={components as Array<AboutItemInterface>} />;
    default:
      return '';
  }
};

