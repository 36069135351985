import { GetServerSideProps } from 'next';

import { Section } from '@/core/components/Section/Section';
import { Banner } from '@/core/components/Layout/Banner/Banner';

import { api } from '@/features/Homepage/api';
import { HomePageProps } from '@/features/Homepage/homepage.types';

const Page = ({ config }: HomePageProps) => {
  const { sections } = config;

  return (
    <>
      <Banner
        title="This is a catchy title about Her Excellency"
        subtitle="Her Excellency Reem Al Hashimy UAE Minister of State for International Cooperation"
        image="banner.png"
      />
      <div>
        {sections.map(({
          id,
          ...rest
        }) => (
          <Section
            key={id}
            {...rest}
          />
        ))}
      </div>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async context => {
  let config;

  try {
    const { data } = await api.getConfig();

    config = data;
  } catch (error) {
    return {
      redirect: {
        destination: '/404',
        permanent: true,
      },
      // or use `notFound: true` to only render the /404 page without redirect
    };
  }

  return {
    props: {
      config,
    },
  };
};

export default Page;
