import { Bar } from '@/core/components/Bar/Bar';
import { HeaderWrapper } from '@/core/components/HeaderWrapper';

import {
  BannerWrapper,
  Content,
  Subtitle,
  Title,
} from './Banner.styles';

export type BannerProps = {
  title: string;
  subtitle: string;
  image: string;
}

export const Banner = ({
  title,
  subtitle,
  image,
}: BannerProps) => (
  <HeaderWrapper>
    <BannerWrapper image={image}>
      <Content>
        <Title>{title}</Title>
        <Bar
          percentageWidth={40}
          height={2}
        />
        <Subtitle>{subtitle}</Subtitle>
      </Content>
    </BannerWrapper>
  </HeaderWrapper>
);
