import { useTheme } from 'styled-components';

import { MarginWrapper } from '@/core/components/MarginWrapper';
import { ThemeDirection } from '@/core/constants/constants';
import {
  ArrowLeft,
  ArrowRight,
} from '@/core/components/Icons';

import { SectionConfig } from '@/features/Homepage/homepage.types';

import { renderSectionComponent } from '@/utils/renderSectionComponent';

import { ButtonVariant } from '../Button/Button.types';
import {
  ReadMoreButton,
  Subheader,
  Title,
  TitleWrapper,
  Wrapper,
} from './Section.styles';

type SectionProps = Omit<SectionConfig, 'id'>;

export const Section = ({
  title,
  subtitle,
  link,
  backgroundColor,
  components,
  name,
  align = 'center',
}: SectionProps) => {
  const theme = useTheme();

  return (
    <Wrapper backgroundColor={backgroundColor}>
      <MarginWrapper>
        {subtitle && (
        <Subheader
          hasLink={!!link}
          align={align}
        >
          {subtitle}
        </Subheader>
        )}
        {title && (
          <TitleWrapper
            hasLink={!!link}
            align={align}
          >
            <Title>{title}</Title>
            {link && (
              <ReadMoreButton
                variant={ButtonVariant.GHOST}
                endIcon={theme.direction === ThemeDirection.LTR ? <ArrowRight /> : <ArrowLeft />}
                label="Read more"
              />
            )}
          </TitleWrapper>
        )}
        { components && renderSectionComponent(name, components) }
      </MarginWrapper>
    </Wrapper>
  );
};
