import styled from 'styled-components';

import { breakpoint } from '@/themes/breakpoints';

export const BannerWrapper = styled.div< { image: string } >`
  display: flex;
  align-items: flex-end;
  height: 640px;
  background-image: url(${({ image }) => image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 314px;
  margin-bottom: 10px;
  margin-left: 16px;

  @media ${breakpoint.sm} {
    max-width: 636px;
    margin-bottom: 20px;
    margin-left: 32px;
  }

  @media ${breakpoint.md} {
    max-width: 736px;
    margin-bottom: 30px;
    margin-left: 64px;
  }

  @media ${breakpoint.lg} {
    max-width: 828px;
    margin-bottom: 40px;
    margin-left: 80px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 24px;
  color: ${({ theme: { colors } }) => colors.white};
  font-size: 40px;
  line-height: 48px;

  @media ${breakpoint.sm} {
    font-size: 48px;
    line-height: 56px;
  }

  @media ${breakpoint.md} {
    font-size: 80px;
    line-height: 84px;
  }
`;

export const Subtitle = styled.p`
  margin-top: 24px;
  color: ${({ theme: { colors } }) => colors.neutral[100]};
  font-size: 16px;

  @media ${breakpoint.md} {
    font-size: 18px;
  }
`;
