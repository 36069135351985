import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Nullable } from '@/core/interfaces/common';

import {
  AboutImage,
  TextWrapper,
  Title,
  Wrapper,
} from './AboutItem.styles';

interface AboutItemProps {
  image: string;
  title: string;
  color: string;
  longText: string;
}

export const AboutItem = ({
  image,
  title,
  color,
  longText,
}: AboutItemProps) => {
  const longTextRef = useRef<Nullable<HTMLParagraphElement>>(null);

  const [
    longTextHeight,
    setLongTextHeight,
  ] = useState(0);

  useEffect(() => {
    setLongTextHeight(longTextRef.current?.getBoundingClientRect()?.height || 0);
  }, [longTextRef.current]);

  return (
    <Wrapper>
      <AboutImage
        src={image}
        alt={title}
      />
      <TextWrapper
        offset={longTextHeight}
        color={color}
      >
        <Title>{title}</Title>
        <p ref={longTextRef}>
          { longText}
        </p>
      </TextWrapper>
    </Wrapper>
  );
};
