import styled from 'styled-components';

import { breakpoint } from '@/themes/breakpoints';

export const TextWrapper = styled.div<{ color: string; offset: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 24px;
  background-color: ${({ color }) => color};

  @media ${breakpoint.sm} {
    position: absolute;
    bottom: ${({ offset }) => `${-offset - 32}px`};
    opacity: ${({ offset }) => (offset === 0 ? 0 : 1)};
    transition:
      opacity 0.3s 0.3s,
      bottom 0.3s ease;
  }
`;

export const AboutImage = styled.img`
  filter: grayscale(100%);
  width: 100%;
  max-height: 200px;
  transition: filter 0.5s ease;
  object-fit: cover;
  object-position: 0 0;

  @media ${breakpoint.xs} {
    width: 50%;
    max-height: 100%;
    object-position: 50% 50%;
  }

  @media ${breakpoint.sm} {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${breakpoint.xs} {
    flex-direction: row;
  }

  @media ${breakpoint.sm} {
    overflow: hidden;
    flex-direction: column;

    &:hover {
      ${TextWrapper} {
        position: absolute;
        bottom: 0;
      }
    }
  }

  @media ${breakpoint.md} {
    flex-direction: row;
  }

  &:hover {
    ${AboutImage} {
      &:hover {
        filter: none;
      }
    }
  }
`;

export const Title = styled.h5`
  font-family: var(--executive-website-heading-font-family);
  line-height: 28px;
  margin-block: 0;
`;
