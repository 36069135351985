import { RequestResponse } from '@/utils/request.types';

export enum Sections {
  ABOUT = 'about',
}

export type TitleAlign = 'start' | 'end' | 'center';

export interface BaseComponent {
  id: number;
  name: string;
}

export type SectionComponent = AboutItemInterface | BaseComponent;

export interface AboutItemInterface extends BaseComponent {
  image: string;
  title: string;
  longText: string;
}

export interface SectionConfig {
  id: number;
  name: Sections;
  title?: string;
  subtitle?: string;
  link?: string;
  backgroundColor?: string;
  components?: Array<SectionComponent>;
  align?: TitleAlign;
}

export interface HomePageConfig {
  sections: Array<SectionConfig>;
}

export interface HomePageProps {
  config: HomePageConfig;
}

export type HomePageConfigResponse = RequestResponse<HomePageConfig>;
